import React from 'react';
import './index.css';
import './Bootstrap/css/bootstrap.css';

export default function Career() {
	return (
		<div className="container-md">
			<nav className="navbar navbar-expand-lg navbar-light" style={{backgroundColor: "#ffffff"}}>
			  <div className="container">
				<a className="navbar-brand" href="/">
					<img src="/image/final.png" width="125" height="40" className="d-inline-block align-top" alt="DOTDIP Solutions Inc. Logo"/>
				</a>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
				  <span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarNavDropdown">
				  <ul className="navbar-nav">
					<li className="nav-item">
					  <a className="nav-link active" aria-current="page" href="/" style={{color: "#2556A6"}}>Home</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="/services" style={{color: "#2556A6"}}>Service</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="/career" style={{color: "#2556A6"}}>Career</a>
					</li>
					<li className="nav-item">
					  <a className="nav-link" href="/contact" style={{color: "#2556A6"}}>Contact Us</a>
					</li>
				  </ul>
				</div>
			  </div>
			</nav>
			
			<div className="container" style={{backgroundImage: "url('/image/jobs.jpeg')", textAlign: "center", height: "350px"}}></div>
			
			<h2 className="title">Open Jobs</h2>
			<hr />
			
			<div class="row" style={{margin: "auto", marginBottom: "20px"}}>
			  <div class="col-sm-3">
				<nav className="navbar navbar-light" style={{backgroundColor: "#ffffff"}}>
				  <div className="container-fluid">
					<form className="d-flex">
					  <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
					  <button className="btn btn-outline-primary" type="submit" style={{color: "#2556A6"}}>Search</button>
					</form>
				  </div>
				</nav>
				<h4 className="content" style={{marginTop: "10px"}}>Tags:</h4>
			  </div>
			  <div class="col-sm-9" style={{borderLeft: "2px solid grey"}}>
				<p class="content">Coming Soon...</p>
			  </div>
			</div>
			
			<div className="container" style={{backgroundColor: "#2680EB", color: "white", textAlign: "center", height: "30px"}}>
				<p>© Copyright 2021 - 77 Harbour Sq, Suite 2006, Toronto, ON, M5J 2S2</p>
			</div>
			
		</div>
	);
}
