import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './Bootstrap/css/bootstrap.css';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import Home from "./home";
import Career from "./career";
import Contact from "./contact";
import Services from "./services";

class Main extends React.Component {
	render() {
		return (
		<Router>
			<Switch>
				<Route path="/" exact component={Home} />
				<Route path="/services" component={Services} />
				<Route path="/career" component={Career} />
				<Route path="/contact" component={Contact} />
			</Switch>
		</Router>
		);
	}
}

ReactDOM.render(
  <Main />,
  document.getElementById('root')
);
