import React from 'react';
import './index.css';
import './Bootstrap/css/bootstrap.css';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";

export default function Home() {
	return (
		<div className="container-md">
			<nav className="navbar navbar-expand-lg navbar-light" style={{backgroundColor: "#ffffff"}}>
			  <div className="container">
				<a className="navbar-brand" href="/">
					<img src="/image/final.png" width="125" height="40" className="d-inline-block align-top" alt="DOTDIP Solutions Inc. Logo"/>
				</a>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
				  <span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarNavDropdown">
				  <ul className="navbar-nav">
					<li className="nav-item">
					  <a className="nav-link active" aria-current="page" href="/" style={{color: "#2556A6"}}>Home</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="/services" style={{color: "#2556A6"}}>Service</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="/career" style={{color: "#2556A6"}}>Career</a>
					</li>
					<li className="nav-item">
					  <a className="nav-link" href="/contact" style={{color: "#2556A6"}}>Contact Us</a>
					</li>
				  </ul>
				</div>
			  </div>
			</nav>
			
			<div className="container" style={{backgroundImage: "url('/image/blue.png')", textAlign: "center", height: "350px"}}>
				<div className="h1" style={{color: "#2556A6", paddingTop: "120px", paddingBottom: "50px", fontFamily: "Impact, fantasy"}}>Do One Thing, Do It Perfectly.</div>
				<Link to="/career">
					<button className="btn btn-lg" style={{color: "#2556A6", backgroundColor: "#EAEEF7", border: "1px solid #2556A6"}}>Find Jobs</button>
				</Link>
			</div>
			
			<h2 className="title">About Us</h2>
			<hr />
			<div className="row" style={{marginTop: "20px"}}>
				<div className="col-sm-5">
					<img src="/image/about_us.jpg" style={{width: "95%", marginLeft: "10px"}} alt="consulting group"/>
				</div>
				
				<div className="col-sm-7 content">
					<p className="h4">With 10+ years experience in tech consulting, we have been building strong relationships and trust with our valued clients in the finance industry and public sector. We understand the technology and platform our client is using, we share the same vision where the technology is going, and we are the experts building the best software solutions for client's needs.</p>
					<p className="h4">We have a group of talented individuals who are enthusiastic to reshape the tech world and carve the solutions with the best fitting technology. The individuals are well trained to keep up-to-date with the tech trend and are top professionals in building software solutions.</p>
				</div>
			</div>
			
			<h2 className="title">Our Mission</h2>
			<hr />
			<div className="row content" style={{marginTop: "20px", marginLeft: "10px"}}>			
				<div className="col-sm-9">
					<p className="h4" >We build perfect software solutions by going deep to understand our clients and talents, and connecting them together.</p>
				</div>
				<div className="col-sm-3">
					<img src="/image/mission.png" style={{width: "50%", marginLeft: "20px"}} alt="mission icon"/>
				</div>
			</div>
			
			<h2 className="title">Our Culture</h2>
			<hr />
			<div className="row" style={{marginTop: "20px", marginLeft: "10px"}}>		
				<div className="col-sm-3">
					<img src="/image/culture.jpg" style={{width: "60%", marginLeft: "20px"}} alt="culture icon"/>
				</div>
				<div className="col-sm-9 content">
					<p className="h3" style={{fontWeight: "bold"}}>Do one thing, do it perfectly.</p>
					<p className="h4">Not limited to providing customers with the highest quality and most perfect services and products, we hope that employees can focus on one thing at a time and completely master it to increase the efficiency and the quality.</p>
				</div>
			</div>
			
			<h2 className="title">Our Value</h2>
			<hr />
			
			<div className="row content" style={{marginBottom: "20px"}}>
				<div className="col">
				  <div class="card" style={{backgroundColor: "#8fd3fe"}}>
					<div class="card-body text-center">
					  <h4 className="card-title" style={{fontSize: "26px", textDecoration: "underline", fontWeight: "bold"}}>Integrity</h4>
					  <p class="card-text" style={{fontSize: "20px"}}>Maintaining the trust from clients and the public is our most important responsibility. We aim to continuously provide clients with the highest quality service they expect, and we are being consistent with our words and deeds.</p>
					</div>
				  </div>
				</div>
				
				<div className="col">
				 <div class="card" style={{backgroundColor: "#b5e2ff"}}>
					<div class="card-body text-center">
					  <h4 className="card-title" style={{fontSize: "26px", textDecoration: "underline", fontWeight: "bold"}}>Professionality</h4>
					  <p class="card-text" style={{fontSize: "20px"}}>In terms of professional knowledge, we always keep up with the pace of the times, stay informed on important things, and provide customers with the latest and most professional answers and services.</p>
					</div>
				  </div>
				</div>
				
				<div className="col">
				  <div class="card" style={{backgroundColor: "#daf0ff"}}>
					<div class="card-body text-center">
					  <h4 className="card-title" style={{fontSize: "26px", textDecoration: "underline", fontWeight: "bold"}}>Inclusion</h4>
					  <p class="card-text" style={{fontSize: "20px"}}>We welcome all forms of diversity and respect the uniqueness of every talent. Create an environment of inclusion, trust, and equality to help us stay in the best performance at all times and complete better collaboration.</p>
					</div>
				  </div>
				</div>
			</div>
			
			<div className="container" style={{backgroundColor: "#2680EB", color: "white", textAlign: "center", height: "30px"}}>
				<p>© Copyright 2021 - 77 Harbour Sq, Suite 2006, Toronto, ON, M5J 2S2</p>
			</div>
			
		</div>
	);
}
