import React from 'react';
import './index.css';
import './Bootstrap/css/bootstrap.css';

export default function Contact() {
	return (
		<div className="container-md">
			<nav className="navbar navbar-expand-lg navbar-light" style={{backgroundColor: "#ffffff"}}>
			  <div className="container">
				<a className="navbar-brand" href="/">
					<img src="/image/final.png" width="125" height="40" className="d-inline-block align-top" alt="DOTDIP Solutions Inc. Logo"/>
				</a>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
				  <span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarNavDropdown">
				  <ul className="navbar-nav">
					<li className="nav-item">
					  <a className="nav-link active" aria-current="page" href="/" style={{color: "#2556A6"}}>Home</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="/services" style={{color: "#2556A6"}}>Service</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="/career" style={{color: "#2556A6"}}>Career</a>
					</li>
					<li className="nav-item">
					  <a className="nav-link" href="/contact" style={{color: "#2556A6"}}>Contact Us</a>
					</li>
				  </ul>
				</div>
			  </div>
			</nav>
			
			<h2 style={{fontFamily: "Times, Times New Roman, serif", marginLeft: "10px", color: "#2556A6", fontWeight: "bold"}}>Contact Us</h2>
			<hr />
			
			<div className="row" style={{marginTop: "20px", marginBottom:"20px", textAlign:"center"}}>
				<p className="h4 content">DOTDIP Solution Inc.</p>
				<p className="h4 content">77 Harbour Sq, Suite 2006, Toronto, ON, M5J 2S2</p>
				<a className="h4 content" href="mailto:info@dotdip.ca?subject=Subject&body=Message%20Us%20Your%20Needs">Email Us</a>
				<p className="h4 content">(416)-822-7531</p>
			</div>
			
			<div className="container" style={{backgroundColor: "#2680EB", color: "white", textAlign: "center", height: "30px"}}>
				<p>© Copyright 2021 - 77 Harbour Sq, Suite 2006, Toronto, ON, M5J 2S2</p>
			</div>
			
		</div>
	);
}