import React from 'react';
import './index.css';
import './Bootstrap/css/bootstrap.css';

export default function Services() {
	return (
		<div className="container-md">
			<nav className="navbar navbar-expand-lg navbar-light" style={{backgroundColor: "#ffffff"}}>
			  <div className="container">
				<a className="navbar-brand" href="/">
					<img src="/image/final.png" width="125" height="40" className="d-inline-block align-top" alt="DOTDIP Solutions Inc. Logo"/>
				</a>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
				  <span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarNavDropdown">
				  <ul className="navbar-nav">
					<li className="nav-item">
					  <a className="nav-link active" aria-current="page" href="/" style={{color: "#2556A6"}}>Home</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="/services" style={{color: "#2556A6"}}>Service</a>
					</li>
					<li className="nav-item">
						<a className="nav-link" href="/career" style={{color: "#2556A6"}}>Career</a>
					</li>
					<li className="nav-item">
					  <a className="nav-link" href="/contact" style={{color: "#2556A6"}}>Contact Us</a>
					</li>
				  </ul>
				</div>
			  </div>
			</nav>
			
			<div className="container" style={{backgroundImage: "url('/image/services.png')", textAlign: "center", height: "350px"}}></div>
			
			<h2 className="title">Services</h2>
			<hr />
			
			<p className="h4 content" style={{marginLeft: "10px"}}>We provide professional consulting services for the full life cycle of software development including requirement analysis, software design and architecting, implementation, test,  deployment, and support. Two main sectors that we are providing services to are public sectors such as government, and financial sectors including the major banks.</p>
			
			<h2 className="title">Products</h2>
			<hr />
			
			<div className="row" style={{marginLeft: "10px", marginTop: "10px"}}>
				<div className="col">
					<div className="card mb-3" style={{maxWidth: "540px"}}>
					  <div className="row g-0">
						<div className="col-md-4" style={{backgroundImage: "url('/image/front_end.png')", backgroundRepeat:"no-repeat"}}>
						</div>
						<div className="col-md-8">
						  <div className="card-body" style={{color: "#2556A6"}}>
							<h5 className="card-title content" style={{fontSize: "21px", fontWeight: "bold"}}>Front End UI Design/Development</h5>
							<p className="card-text content" style={{fontSize: "18px"}}>Utilizing the most recent front end frameworks to design/develop web responsive UI layers working on all devices.</p>
						  </div>
						</div>
					  </div>
					</div>
				</div>
				<div className="col">
					<div className="card mb-3" style={{maxWidth: "540px"}}>
					  <div className="row g-0">
						<div className="col-md-4" style={{backgroundImage: "url('/image/back_end.png')", backgroundRepeat:"no-repeat", marginTop: "14px"}}>
						</div>
						<div className="col-md-8">
						  <div className="card-body" style={{color: "#2556A6"}}>
							<h5 className="card-title content" style={{fontSize: "21px", fontWeight: "bold"}}>Backend Micro-services Development</h5>
							<p className="card-text content" style={{fontSize: "18px"}}>API design and implementation for backend micro-services supporting both functional and non-functional needs.</p>
						  </div>
						</div>
					  </div>
					</div>
				</div>
			</div>
			<div className="row" style={{marginLeft: "10px", marginTop: "10px", marginBottom: "20px"}}>
				<div className="col">
					<div className="card mb-3" style={{maxWidth: "540px"}}>
					  <div className="row g-0">
						<div className="col-md-4" style={{backgroundImage: "url('/image/database.png')", backgroundRepeat:"no-repeat"}}>
						</div>
						<div className="col-md-8">
						  <div className="card-body" style={{color: "#2556A6"}}>
							<h5 className="card-title content" style={{fontSize: "21px", fontWeight: "bold"}}>Database Development</h5>
							<p className="card-text content" style={{fontSize: "18px"}}>Creating new or extending existing databases for OLTP and OLAP with well tuned performance.</p>
						  </div>
						</div>
					  </div>
					</div>
				</div>
				<div className="col">
					<div className="card mb-3" style={{maxWidth: "540px"}}>
					  <div className="row g-0">
						<div className="col-md-4" style={{backgroundImage: "url('/image/cloud.jpg')", backgroundRepeat:"no-repeat"}}>
						</div>
						<div className="col-md-8">
						  <div className="card-body" style={{color: "#2556A6"}}>
							<h5 className="card-title content" style={{fontSize: "21px", fontWeight: "bold"}}>Clound Development</h5>
							<p className="card-text content" style={{fontSize: "18px"}}>Building cloud native applications and migrating legacy monolithic applications to cloud to maximize flexibility and extensibility.</p>
						  </div>
						</div>
					  </div>
					</div>
				</div>
			</div>
			
			<div className="container" style={{backgroundColor: "#2680EB", color: "white", textAlign: "center", height: "30px"}}>
				<p>© Copyright 2021 - 77 Harbour Sq, Suite 2006, Toronto, ON, M5J 2S2</p>
			</div>
			
		</div>
	);
}